import React from 'react';
import Navbars from '../hooks/Navbars';
import { GoDash } from "react-icons/go";
import Footer from "../hooks/Footer";
import { Link } from "react-router-dom";
import AccountReview from '../hooks/AccountReview';


const Crypto = () => {
  const CustomeIcon = {
    fontSize: "25px",
    '--custom-icon-color': '#0e6af4'
}
  return (
   <>
    <div className=''>
      <Navbars/>
    </div>

    <div className='mx-auto overflow-hidden md:max-w-7xl'>
      <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[1%]'>
        <div className='bg-gren-600 h-fit'>
            <div className='xl:pt-[35px] pt-0'>
              <img src="https://img.freepik.com/free-photo/hand-pressing-security-button-touch-screen_1112-492.jpg?t=st=1715563062~exp=1715566662~hmac=1b0f916538d8f476665f45bf3ec13ea0b0d3c35dd751517ed3a7709685ba6e88&w=1480" alt="" />
            </div>
        </div>
          <div className='bg-gren-500 h-fit xl:pl-5 pl-1'>
            <div className='flex'>
                <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4] uppercase'>Services</span>
            </div>

            <div>
                <p className='font-bold text-[30px] xl:text-[40px] text-[black] uppercase'>Account Unlock &  Activation</p>
            </div>

            <div className='pt-5'>
              <p className='font-bold text-[#0e6af4]'>Unlocking Your Accounts: Understanding the Essentials and Ensuring Safety</p>
            </div>

            <div className='pt-5'>
              <p>Inconvenience from losing access to vital systems disrupts our daily lives and progress. At our esteemed organization, we understand this urgency, driving us to prioritize our exceptional account recovery service. Whether you need access to personal profiles or help reclaiming devices within legal bounds, our dedicated Apextecie team offers unwavering support. Armed with cutting-edge tools, we overcome intricate account challenges</p>
            </div>
            <div className='pt-6'>
              <p>
              Rest assured, no platform poses a puzzle too daunting. With profound proficiency and unwavering commitment, we adeptly navigate obstacles, ensuring swift access to critical profiles. Don't let account hurdles impede progress. Trust our capabilities and regain control over your digital domain.
              </p>
            </div>
            
          </div>
      </div>
    </div>
    <div className='bg-[#0E6AF4] w-full h-fit p-4 overflow-hidden'>
        <div className='mx-auto md:max-w-4xl overflow-hidden'>
            <div className='grid  grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 overflow-hidden'>
                <div className='overflow-hidden'>
                 <div className='flex'>
                 <img src="shield.png" alt="" className='w-[64px]'/>
                  <div className='pl-5'>
                    <p className='font-bold text-white pt-3'>Stop worrying, our recovery process is guaranteed.</p>
                  </div>
                 </div>
                </div>
                <div>
                 <div className='xl:ml-[15%] ml-1'>
                   <a href="/contact">
                    <button className='bg-[#1d1829] p-4 text-white btn__touch1'>GET IN TOUCH</button>
                   </a>
                 </div>
                </div>
            </div>
        </div>
    </div>
          <div className='bg-[#1d1829] h-fit'>
             <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-2 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Top Testimonies</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Company's Top Reviews on Account Unlock.</p>
                        </div>
                    </div>
                    <div className='bg-gren-500 h-fit'>
                        <p className='xl:pt-5 pt-0 text-[#b1b1b1]'>
                        At Apextechie, we've earned top reviews for our exceptional expertise in phone spy and unlock services. Our dedicated team ensures seamless access to target devices, garnering praise from satisfied clients worldwide. Trust us for reliable solutions and unmatched professionalism.
                        </p>
                      </div>
                      </div>
                </div>

                <div className='mx-auto md:max-w-7xl overflow-hidden pb-10'>
                  <AccountReview/>
                </div>
            </div>

        <div className=''>
            <div className='mx-auto overflow-hidden md:max-w-7xl'>
                <div className='grid mt-10 xl:mt-0 ml-3 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                    <div className='bg-gren-500 h-fit'>
                        <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>Why Choose Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[black]'>Why Choose Apextechie For Account Unloock & Activation</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p>Apextechie prioritizes seamless access to your vital accounts with our exceptional account unlock and activation service. Our dedicated team ensures swift resolution, whether you're reclaiming access for legitimate reasons or within legal boundaries. With cutting-edge tools and unwavering commitment, we adeptly navigate obstacles to empower you with control over your digital domain. Trust Apextechie for transparent communication, tailored solutions, and unparalleled customer satisfaction throughout the process.</p>
                        </div>

                        <div className='bg-geen-600 h-fit mt-0 xl:mt-5'>
                            <div className='flex justify-center'>
                                <div className='bg-ed-600 h-fit w-full'>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Deactivated Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Recliam Funds From Suspended or Locked Bank Account</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Suspended Social Media Account Recovery</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-range-600 h-fit w-full xl:block hidden'>
                                <div className='flex '>
                                <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className='bg-range-600 h-fit w-full xl:hidden block'>
                                <div className='flex '>
                                      
                                    </div>
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation of Locked Crypto wallet</p>
                                        </div>
                                    </div>
                                    {/* <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#a5c638]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>Activation Of suspended Social Media Account</p>
                                        </div>
                                    </div> */}
                                    <div className='flex'>
                                      <div>
                                         <i class="ri-checkbox-circle-fill text-[#0E6AF4]" style={CustomeIcon}></i>
                                      </div>
                                        <div className='pl-2 pt-2'>
                                            <p className='font-bold'>24hrs Protection</p>
                                        </div>
                                    </div>
                                    
                                </div>

                               
                       
                        </div>
                            
                    </div>
                    <div className='bg-gren-500 h-fit'>
                      <div className='shadow-md xl:mt-10 mt-0'>
                        <img src="https://img.freepik.com/free-photo/female-scientist-white_53876-89029.jpg?t=st=1715563967~exp=1715567567~hmac=3dfa3122e180bc8eaa41fca5a4c303cb775211b7cede15a1e7976ca586d24893&w=1480" alt=""  className='rounded-md shadow-md img__border'/>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="hero">
                        <div class="hero-content">
                            <h2 className="text-center">Need our professional services?</h2>
                            <p className="text-center">Contact us now to get started!</p>
                            <Link to="/Contact" class="contact-btn">Contact Us</Link>
                        </div>
                    </section>

      <Footer/>
   </>
  )
}

export default Crypto;