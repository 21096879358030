import React from 'react';
import Navbars from '../hooks/Navbars';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { GoDash } from "react-icons/go";
import { IoMdCheckmark } from "react-icons/io";
import Footer from "../hooks/Footer";


const About = () => {
  return (
    <>
    <div className='box1 overflow-hidden'>
        <section class="About">
            <div className='relative'>
                <Navbars/>
            </div>
          <div className='mx-auto overflow-hidden  md:max-w-7xl'></div>
          <div class="herox-content relative top-30 left-2 xl:left-10 text-left text-white z-10">
            <div className='mx-auto overflow-hidden  md:max-w-full'>
              <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className="xl:mx-[15%]  mx-[5%] mt-[5%] overflow-hidden  md:max-w-2xl bg-[reen] h-fit relative top-30 left-2 xl:left-10 text-left text-white z-10 pb-[5%] xl:pb-[3%] pt-[10%]">
                  <motion.div className="flex xl:pt-5 pt-10 mt-[20%]"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.3, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <Link to="/"><p>Home<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                    <Link to="/"><p>Services<i class="ri-arrow-right-s-line text-[#0779e4]"></i></p></Link>
                  </motion.div>

                  <motion.div className="h-fit"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    variants={{
                      hidden: { opacity: 0, x: -50 },
                      visible: { opacity: 1, x: 0 },
                    }}>
                    <p className=" font-bold xl:text-[2.5rem] text-[2rem]">
                    About <br /> Apextechie
                    </p>
                  </motion.div>
                </div>
                <div className='h-fit w-fit'>

                </div>
              </div>
            </div>
          </div>
        </section>
        </div>
            <div className='bg-[#1d1829]'>

            </div>
        <div className='mx-auto md:max-w-7xl overflow-hidden xl:mt-10 mt-1'>
            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-10 pb-10 pt-[5%] shadow__abt '>
                <div className='bg-gren-600 h-fit'>
                    <div>
                        <p>Apextechie empathizes deeply with the profound impact that fraud or scams can impose on their victims. Initially, the repercussions are twofold: an immediate financial setback, where substantial amounts of money, both in traditional currencies and cryptocurrencies, fall into the hands of scammers. Equally significant is the emotional toll that follows as individuals come to terms with being deceived, often grappling with feelings of embarrassment or shame. However, if you find yourself ensnared in the web of a financial or crypto scam, rest assured that you are not alone. Disturbingly, data reveals that hundreds of millions of pounds and USD worth of unauthorized payments flow into the pockets of scammers annually within the cryptocurrency space. This surge in fraudulent activities has been marked as a concerning trend, prompting the trade association for cryptocurrency, banking, and financial services to label it a 'national security threat.</p>
                    </div>
                    <div className='pt-3'>
                        <p>Yet, amid the disheartening statistics and escalating scams, there is hope for those who have suffered financial losses due to fraud or scams. Revokedev is here to guide you through the recovery process, offering a supportive hand to reclaim what's rightfully yours. Our dedicated efforts are centered on assisting individuals who have fallen victim to scams, providing a path towards financial recovery and security.</p>
                    </div>
                </div>
                <div className='bg-green-600 h-fit'>
                    <img src="https://img.freepik.com/free-photo/developers-brainstorming-ideas-looking-code-computer-screens-asking-feedback-from-senior-developer-while-intern-joins-discussion-junior-programmers-collaborating-group-project_482257-41852.jpg?t=st=1715732222~exp=1715735822~hmac=0a3b3ff6315b5d4b158a7a79b4af0e6ee59288a17c58217568fd3fbb278efaaf&w=1800" alt="" />
                </div>
            </div>
        </div>
        <div className='bg-[#1d1829] mt-10'>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
                <div className='xl:block hidden'>
                    <img src="https://img.freepik.com/free-photo/cyber-security-company-developers-encrypting-cloud-processing-data-system-using-blockchain-technology-cybernetics-engineers-reviewing-lines-code-order-secure-mainframe-database-storage_482257-38975.jpg?t=st=1715733444~exp=1715737044~hmac=ff3a0ba172370f6a5b64af86ea2a8f6e8cbfdde3af38975e0937dce9a9db99a1&w=1480" alt="" />
                </div>
                <div>
                    <div>
                    <div className='flex'>
                            <GoDash size={35} color='#0e6af4'/> <span className='pt-2 font-bold text-[#0e6af4]'>About Us</span>
                        </div>

                        <div>
                            <p className='font-bold text-[30px] xl:text-[40px] text-[white]'>Our Strategy</p>
                        </div>
                        <div className='pt-5 pr-3 pb-5 xl:pb-0'>
                          <p className='text-[#d6d6d6]'>We work as a group of teams, every professional with his/her specialization. Every agent plays their role respectively so optimal solutions are obtained from a universal approach. All these multiple standard practices enable us to deliver optimal solutions to our clients.</p>
                        </div>
                    </div>
                    <div className='flex mt-5'>
                        <div className='bg-gren-600 h-fit w-full'>
                            <div className='flex'>
                                <IoMdCheckmark  className='text-[20px] font-bold text-[#0E6AF4]'/>  <span className='font-bold text-[white]'>Best Quality Support</span>
                            </div>
                            <div className='flex pt-3'>
                                <IoMdCheckmark  className='text-[20px] font-bold text-[#0E6AF4]'/>  <span className='font-bold text-white'>Money Recovery Guaranteed</span>
                            </div>
                        </div>
                        <div className='bg-rd-600 h-fit w-full'>
                            <div className='flex'>
                                <IoMdCheckmark  className='text-[20px] font-bold text-[#0E6AF4]'/>  <span className='font-bold text-white'>We Serve the Best</span>
                            </div>
                            <div className='flex pt-3'>
                                <IoMdCheckmark  className='text-[20px] font-bold text-[#0E6AF4]'/>  <span className='font-bold text-white'>100% Trust & Security</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='mx-auto md:max-w-7xl overflow-hidden'>
            <div className='flex justify-center'>
                <p className='uppercase text-[30px] xl:text-[40px] font-bold text-white'>Our Visison And Mission</p>
            </div>
            <div className='grid mt-10 xl:mt-0 ml-2 mr-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-5 pb-10 pt-[5%]'>
            <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="missionx.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Our Mission</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            To establish a world where trust and digital safety prevail. We envision a future where individuals can navigate the digital realm with confidence, knowing that they have a steadfast ally in Revokedev. We aspire to set new standards for online security, financial restitution, and victim support, reshaping the landscape of digital trust and protection.
                            </p>
                        </div>

                      
                        
             </motion.div>
             <motion.div className='bg-reen-600 h-full shadow__box p-5'
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}              
                    variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                    }}>
                        <div className='bg--300 w-fit border__b'>
                            <div className='p-2'>
                                <img src="visionx.gif" alt="" className='w-[60px]'/>
                            </div>
                        </div>

                        <div className='pt-4'>
                            <p className='font-bold text-white'>Our Vision</p>
                        </div>

                        <div className='pt-3'>
                            <p className='text-white'>
                            To relentlessly champion the rights of victims and combat the ever-evolving landscape of financial fraud, online scams, and digital threats. We empower individuals, reclaim stolen assets, restore digital security, and provide unwavering support to those in need. Our mission is to bring financial justice and peace of mind to those affected by deception, with Revokedev leading the charge.
                            </p>
                        </div>

                      
                        
             </motion.div>
            </div>
        </div>
        </div>

        <div>
            <Footer/>
        </div>
        
    </>
  )
}

export default About