import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/a11y'; 
import { Avatar } from '@mui/material';

const MySwiper = () => {
  

  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={20}
      slidesPerView={1} // Show one slide on all mobile devices
      slidesPerGroup={1}
      breakpoints={{
        // When window width is >= 640px
        640: {
          slidesPerView: 2, // Show two slides on smaller desktop screens
          slidesPerGroup: 2,
        },
        671: {
          slidesPerView: 1, // Show one slide on medium screens (md)
          slidesPerGroup: 1,
        },
        // When window width is >= 1024px (desktop lg)
        1024: {
          slidesPerView: 3, // Show three slides on desktop (lg)
          slidesPerGroup: 3,
        },
      }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
     className='!ml-5 !mr-5 !mt-5'>
      <SwiperSlide>
            <div className='border__b rounded-md '>
            <div className="bg-[reen] h-fit  pt-[5%]  ">
                    <div className='flex justify-center'>
                        
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/4e/c4/3d/4ec43d79f28a1d00aff692229aeeecf0.jpg' alt=""/>
                            </Avatar>
                        
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Magnolia Hawthorne</p>
                    </div>
                   

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I never thought I'd find myself in such a horrifying situation. Someone had obtained my private photos and started 
                    blackmailing me for over $70,000. I felt helpless and violated. Thankfully, a friend recommended Apextechie, 
                    and they immediately went to work. Not only did they recover the money taken from me, but they also helped trace the 
                    blackmailer, bringing them to justice. I can't express how grateful I am for their expertise and support.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Louisiana • USA •  4 weeks ago</p>
                    </div>
                </div>

                </div>  
            </div>
                 
          
      </SwiperSlide>

      <SwiperSlide>
       <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md ">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://media.istockphoto.com/id/1079932454/photo/selfie-in-bedroom.jpg?s=612x612&w=0&k=20&c=Sqj21EF0ppcBVzkUJ47DAgbp8buiedjCfW3OQCeOXfU=' alt=""/>
                            </Avatar>
                        
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Jethro Beauregard</p>
                    </div>
                    

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I received terrifying threats from an anonymous person who claimed to have my address. They demanded $47,000, 
                    threatening to harm my family if I didn't comply. I was living in constant fear until I contacted Apextechie
                    Backs. They not only eliminated the online threat but also assisted in tracking down the perpetrator and ensuring 
                    justice was served. Apextechie truly saved my life and my family's.
                    </p>


                    <div className='pl-2 pb-3 pt-[5%]'>
                        <p className='text-[#b0b0b0] text-center '> Mississippi • USA • 5 days Ago</p>
                    </div>
                </div>

                </div>   
        </div>
      </SwiperSlide>

      <SwiperSlide>
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/736x/f4/da/ec/f4daeccf7a4fef4600d13d11ee15b98d.jpg' alt=""/>
                            </Avatar>
                       
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Jessy Hartel</p>
                    </div>
                   

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    As a public figure, my reputation is everything. When compromising information was used against me for extortion, I 
                    was devastated. Apextechie provided unwavering support and expertise. They not only prevented the release of 
                    sensitive information but also tracked down the extortionist, ensuring they faced the consequences of their actions. 
                    Thanks to Apextechie, my reputation remains intact.
                    </p>


                    <div className='pl-2 pb-3 pt-10'>
                        <p className='text-[#b0b0b0] text-center'> Scotland • United Kingdom • 2 weeks ago</p>
                    </div>
                </div>

                </div>   
    </div>
      </SwiperSlide>

      <SwiperSlide>
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                        
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://i.pinimg.com/originals/7c/ff/e6/7cffe6d529f700815690165f4e264d1f.png' alt=""/>
                            </Avatar>
                        
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Elodie Dupont</p>
                    </div>
                   

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    I became a victim of relentless cyberbullying and blackmail that took a toll on my mental health. Apextechie stepped in to help. They not only put an end to the bullying but also identified the culprit. With their 
                    support, I regained control of my life and felt empowered to stand up against online harassment. Apextechie
                    Backs is a beacon of hope for those facing similar situations.
                    </p>


                    <div className='pl-2 pb-3 pt-3'>
                        <p className='text-[#b0b0b0] text-center'> Quebec • Canada • 12 days Ago</p>
                    </div>
                </div>

                </div>   
        </div>
      </SwiperSlide>

      <SwiperSlide>
      <div className=" h-fit overflow-hidden  ml-2 mr-2 border__b rounded-md">
                <div className="bg-[reen] h-fit  pt-[5%]">
                    <div className='flex justify-center'>
                       
                            <Avatar className='!w-[50px] !h-[50px] '>
                                <img src='https://1.bp.blogspot.com/-v0RkfzSyp00/YQPrkDSMcJI/AAAAAAAALf0/ZQQUSUFC_Vgcx7gSaV3d_TNKhraSM-_GwCLcBGAsYHQ/s752/attractive-men-fit-body-arm-tattoo-selfie.jpg' alt=""/>
                            </Avatar>
                        
                    </div>
                    <div className='flex justify-center'>
                        <p className="text-center font-bold pt-3 text-[#c0c2cd]">Liam Callaghan</p>
                    </div>
                    

                    <div className='pl-1 pr-1'>
                    <p className='pt-5 text-center pb-0 text-[#e3e3e5]'>
                    My family's safety was at risk as an online blackmailer threatened to harm them if I didn't pay a 
                    hefty sum. It felt like a nightmare. Fortunately, I reached out to Apextechie, who not only 
                    eliminated the threat but also cooperated with law enforcement to ensure the blackmailer faced justice.
                     Thanks to their expertise and dedication, my family is safe, and justice prevailed.
                    </p>


                    <div className='pl-2 pb-3 pt-5'>
                        <p className='text-[#b0b0b0] text-center'> Ontario • Canada • 1 month Ago</p>
                    </div>
                </div>

                </div>   
           </div>
      </SwiperSlide>
     
    </Swiper>
  );
};

export default MySwiper;
